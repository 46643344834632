// Entry point for the build script in your package.json

import "@hotwired/turbo-rails"
import "./controllers"
Turbo.session.drive = false
import * as bootstrap from "bootstrap"
import * as Popper from "@popperjs/core"
window.bootstrap = bootstrap
window.Popper = Popper
import { library, dom } from '@fortawesome/fontawesome-svg-core'

import {
  faLinkedin,
  faInstagram
} from '@fortawesome/free-brands-svg-icons'

import { 
  faCircleNodes,
  faPresentationScreen,
  faSquareInfo,
  faUsersViewfinder,
  faTypewriter,
  faPhoneIntercom,
  faCameraWeb,
  faScrewdriverWrench,
  faSparkles,
  faChalkboardUser,
  faCalendarClock,
  faCommentPen,
  faMapLocation,
  faUsers,
  faBriefcase,
  faBadgeCheck,
  faLaptopMobile,
  faMicrochipAi,
  faUserTie,
  faCode,
  faMagnifyingGlassChart,
  faPenRuler,
  faListCheck,
  faBookOpenReader,
  faSquareQuestion,
  faRightToBracket,
  faUser
} from '@fortawesome/pro-duotone-svg-icons'

library.add(
  faLinkedin,
  faInstagram,
  faCircleNodes,
  faPresentationScreen,
  faSquareInfo,
  faUsersViewfinder,
  faTypewriter,
  faPhoneIntercom,
  faCameraWeb,
  faScrewdriverWrench,
  faPresentationScreen,
  faSparkles,
  faChalkboardUser,
  faCalendarClock,
  faCommentPen,
  faMapLocation,
  faUsers,
  faBriefcase,
  faBadgeCheck,
  faLaptopMobile,
  faMicrochipAi,
  faUserTie,
  faCode,
  faMagnifyingGlassChart,
  faPenRuler,
  faListCheck,
  faBookOpenReader,
  faSquareQuestion,
  faRightToBracket,
  faUser
)

dom.watch()

function bootstrapValidation() {
  'use strict'

  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  const forms = document.querySelectorAll('.needs-validation')

  // Loop over them and prevent submission
  Array.from(forms).forEach(form => {
    form.addEventListener('submit', event => {
      if (!form.checkValidity()) {
        event.preventDefault()
        event.stopPropagation()
      }

      form.classList.add('was-validated')
    }, false)
  })

}

document.addEventListener("turbo:load", function () {
  bootstrapValidation();
})